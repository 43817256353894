<template>
  <g :fill="color" fill-rule="evenodd" transform="translate(1 1.4)">
    <path
      fill-rule="nonzero"
      d="M4.37 20.701l.627 2.593c.32 1.323 
    1.567 1.656 2.509.671l3.124-3.264h1.783a.6.6 0 0 0 0-1.2h-2.295l-3.479 3.634c-.265.278-.364.278-.453-.038l-.873-3.596H3.216C1.77 19.501.6 18.337.6 16.908V3.194C.6 1.759 1.77.6 3.227.6h16.049c1.452 0 2.627 1.166 2.627 2.602v8.766a.6.6 0 1 0 1.2 0V3.202c0-2.101-1.714-3.802-3.827-3.802H3.227C1.109-.6-.6 1.094-.6 3.194v13.714C-.6 19 1.11 20.7 3.216 20.7h1.153z"
    />
    <rect width="14.491" height="1.2" x="4.025" y="5.6" rx=".6" />
    <rect width="14.491" height="1.2" x="4.025" y="11.2" rx=".6" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
